import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
  return (
    <footer className="footer_1">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-6 col-md-5">
            <aside className="widget aboutwidget">
              <a href="/">
              <StaticImage
                  src="../assets/images/logo-blue.png"
                  alt="Logo"
                  width={350}
                  height={112}
                  style={{marginBottom:"34px"}}
                  formats={["auto", "webp", "avif"]}
                />
              </a>
              <p>
                We work differently. No commissions. No placement fees. No retainer. No mark-up fees.
              </p>
            </aside>
          </div>
          <div className="col-lg-4 col-sm-4 col-md-4">
            <aside className="widget contact_widgets">
              <h3 className="widget_title">contact Information</h3>
              <div className="col-lg-12 col-sm-12 col-md-12">
                <p>
                <Link to="/contact">Send a Message</Link>
                </p>
                <p>Nashville, Tennessee</p>
                <p>Phone: 615.461.5742</p>
              </div>
            </aside>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div className="copyright">
              © copyright {new Date().getFullYear()} by{" Maday Talent Partners"}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
