import React, { useState, useEffect, useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { NavLinks } from "@/data";
import { MenuContext } from "@/context/menu-context";
import { BarsMenuIcon } from "@/components/svg-bars-menu";
import { LinkedInIcon } from "@/components/svg-linkedin";

const HeaderTwo = () => {
  const [sticky, setSticky] = useState(false);
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const handleMenuClick = e => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);

  return (
    <header
      className={`header_01 black_color ${true === sticky ? "fixedHeader animated flipInX" : null
        }`}
      id="header"
    >
      <Container fluid>
        <Row className="justify-content-between">
          <Col className="col-6" lg={2} md={3} sm={3}>
            <div className="logo">
              <Link to="/">
                <StaticImage
                  src="../assets/images/logo-black.png"
                  alt="Logo"
                  width={350}
                  height={112}
                  formats={["auto", "webp", "avif"]}
                />
              </Link>
            </div>
          </Col>
          <Col lg={8} sm={8} md={7} className="d-none d-lg-block ">
            <nav className="mainmenu text-center">
              <ul>
                {NavLinks.map((links, index) => {
                  return (
                    <li
                      key={index}
                      className={`${undefined !== links.subItems
                        ? "menu-item-has-children"
                        : ""
                        }`}
                    >
                      {links?.target ?
                        <a href={links.url} target={links.target}>{links.name}</a> :
                        <Link to={links.url}>{links.name}</Link>
                      }
                      {undefined !== links.subItems ? (
                        <ul className="sub-menu">
                          {links.subItems.map((subLinks, index) => (
                            <li key={index}>
                              <Link to={subLinks.url}>{subLinks.name}</Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  );
                })}
                <li><a href="https://www.linkedin.com/in/brenda-maday-76548b2" rel="noreferrer noopener" target="_blank"><LinkedInIcon/></a></li>
              </ul>
            </nav>
          </Col>
          <Col lg={2} md={2} sm={4} className="col-6">
            <div className="navigator text-right" style={{ float: "right", paddingRight: "30px" }}>
              <a
                href="#"
                className="menu mobilemenu d-none d-md-none d-lg-none"
              >
                <BarsMenuIcon />
              </a>
              <a
                id="open-overlay-nav"
                className="menu hamburger"
                onClick={handleMenuClick}
                href="#"
              >
                <BarsMenuIcon />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default HeaderTwo;
